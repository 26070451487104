<template>
    <modal ref="modalOtorgarCredito" titulo="Método de pago" tamano="modal-lg" icon="money" :cargado-modal="loading" no-aceptar adicional="Guardar" @adicional="guardar_credito">
        <div class="row justify-center">
            <p class="text-general">La gestión del crédito será realizada en &nbsp; <strong> {{ pedido.sigla_moneda }}</strong></p>
        </div>
        <div class="row mx-0 my-3 text-general">
            <div class="col d-flex align-items-end">
                <div class="mx-5">
                    <p class="justify-center px-0">
                        <b class="f-15">
                            Total
                        </b>
                        {{ separadorNumero((resumen.val_total + parseFloat(resumen.val_condicion)),pedido.decimales_moneda) }}
                    </p>
                </div>
            </div>
            <div class="col px-3">
                <label class="pl-3 f-15"> Anticipo </label>
                <div class="d-middle">
                    <money v-model="model.anticipo" class="input-money" v-bind="money" />
                </div>
            </div>
            <div class="col px-3">
                <label class="pl-3 f-15"> Saldo Pendiente </label>
                <p class="br-10 text-center py-1 border-general text-gr-general">{{ separadorNumero(saldo_pendiente,pedido.decimales_moneda) }}</p>
            </div>
        </div>
        <div class="row mx-5 my-4 text-general">
            <div class="col-3">
                <label class="f-15 tres-puntos"> Numero Cuotas </label>
                <el-input-number v-model="numero_cuotas" size="small" controls-position="right" :min="1" />
            </div>
            <div class="col-5 ml-5 pt-2">
                <p class="pl-3 f-15 tres-puntos"> Periodicidad </p>
                <el-select v-model="periodicidad" size="small" placeholder="Periodicidad">
                    <el-option
                    v-for="item in periodos"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
            <div class="col d-flex align-items-end justify-content-end">
                <div class="text-white bg-general text-white br-12 cr-pointer text-center py-2 w-75" @click="generar_cuotas">
                    Crear
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12">
                <hr class="border my-2" />
            </div>
        </div>
        <template v-show="crear">
            <div v-for="(data, i) in cuotas" :key="i" class="row mx-0 mb-2 px-3">
                <div class="col-auto d-middle text-general f-600">
                    {{ i + 1 }}.
                </div>
                <div class="col px-1">
                    <el-date-picker
                    v-model="data.fecha"
                    class="w-100"
                    type="date"
                    format="dd-MMM-yyyy"
                    value-format="yyyy-MM-dd"
                    size="small"
                    :clearable="false"
                    :picker-options="pickerOptions"
                    @change="validar_fechas($event,data)"
                    @focus="fun_focus"
                    />
                </div>
                <div class="col" />
                <div class="col px-1">
                    <money v-model="data.valor" class="input-money" v-bind="money" />
                </div>
            </div>
        </template>
        <div v-show="!meta && crear" class="row justify-center">
            <span class="f-12 text-danger">El Total de las cuotas debe ser igual al saldo pendiente.</span>
        </div>
        <div class="row mx-0">
            <div class="col-12">
                <hr class="border-separador my-2" />
            </div>
        </div>
        <div v-show="crear" class="row mx-0 position-fixed position-sticky bg-white py-2" style="bottom:-15px">
            <div class="col-auto d-middle f-600" />
            <div class="col px-1 d-middle-center text-general">
                Cuotas: <span class=" mx-2"> {{ numero_cuotas }} </span>
            </div>
            <div class="col d-middle justify-content-end text-general tres-puntos">
                Total de cuotas.
            </div>
            <div class="col px-1">
                <p
                class="br-10 text-center py-1"
                :class="`${meta ? 'text-general border-black': 'text-general-red border-general'}`"
                >
                    {{ separadorNumero(total_cuotas,pedido.decimales_moneda) }}
                </p>
            </div>
            <div class="col-auto mx-4" />
        </div>
    </modal>
</template>
<script>
import {Money} from 'v-money'
import moment from 'moment'
import Pedidos from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    components:{
        Money,
    },
    data(){
        return {
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < Date.now()
                },
            },
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            model:{
                anticipo:0
            },
            crear:false,
            numero_cuotas: 1,
            periodos: [ { value: 1, label: 'Diario' }, { value: 2, label: 'Semanal' }, { value: 3, label: 'Quincenal' }, { value: 4, label: 'Mensual' } ],
            periodicidad: '',
            cuotas:[],
            fecha_calendario:null,
            loading:false,
        }
    },
    computed:{
        id_pedido(){
            if(this.$route.name == 'admin.almacen.pedidos'){
                return this.$store.getters['pedidos/pedidos_admin/id_pedido']
            }else if(this.$route.name == 'admin.almacen.historial'){
                return this.$store.getters['pedidos/pedidos_historial_admin/id_pedido']
            }
            return this.$store.getters['pedidos/pedidos_admin/id_pedido']
        },
        pedido(){
            if(this.$route.name == 'admin.almacen.pedidos'){
                return this.$store.getters['pedidos/pedidos_admin/pedido']
            }else if(this.$route.name == 'admin.almacen.historial'){
                return this.$store.getters['pedidos/pedidos_historial_admin/pedido']
            }
            return this.$store.getters['pedidos/pedidos_admin/pedido']
        },
        resumen(){
            if(this.$route.name == 'admin.almacen.pedidos'){
                return this.$store.getters['pedidos/pedidos_admin/resumen']
            }else if(this.$route.name == 'admin.almacen.historial'){
                return this.$store.getters['pedidos/pedidos_historial_admin/resumen']
            }
            return this.$store.getters['pedidos/pedidos_admin/resumen']
        },
        saldo_pendiente(){
            return (this.resumen.val_total + parseFloat(this.resumen.val_condicion)) - this.model.anticipo
        },
        total_cuotas(){
            return _.sumBy(this.cuotas,'valor')
        },
        meta(){
            if(this.saldo_pendiente === this.total_cuotas){
                return true
            }else{
                return false
            }
        }
    },
    methods: {
        fun_focus(d){
            this.fecha_calendario = d.value
        },
        toggle(){
            this.cuotas = []
            this.crear = false,
            this.model.anticipo = 0
            this.numero_cuotas = 1
            this.periodicidad = ''
            this.fecha_calendario = null
            this.money.precision = this.pedido.decimales_moneda
            this.$refs.modalOtorgarCredito.toggle();
        },
        async guardar_credito(){
            try {
                if(this.meta === false){
                    this.notificacion('Advertencia','El Total de las cuotas debe ser igual al saldo pendiente.','warning')
                    return
                }
                if(this.saldo_pendiente === 0){
                    this.notificacion('Advertencia','No se puede generar credito para un saldo $0','warning')
                    return
                }
                if(this.id_pedido === null){
                    this.notificacion('Alerta','Por favor seleccione un pedido.','warning')
                    return
                }
                this.model.cuotas = this.cuotas
                this.loading = true
                const {data} = await Pedidos.crear_credito(this.id_pedido, this.model)
                this.notificacion('','Crédito asignado correctamente','success')
                this.$emit('credito')
                this.$refs.modalOtorgarCredito.toggle();

            } catch (e){
                this.error_catch(e)
            } finally{
                this.loading = false
            }
        },
        validar_fechas(e,obj){
            let d = this.cuotas.filter(o=>o.fecha === e)
            if(d.length > 1){
                let f = moment(e).format('DD MMM YYYY')
                this.notificacion('Duplicado',`La fecha <strong>${f}</strong> ya esta asignada. </br> <strong>Por favor elija otra.</strong>`,'warning')
                obj.fecha = this.fecha_calendario
            }
            return
        },
        generar_cuotas(){
            if(this.periodicidad === '') return
            this.crear = true
            this.cuotas = []

            let valor_cuota = 0
            if(this.pedido.decimales_moneda > 0){
                valor_cuota = parseFloat(this.saldo_pendiente / this.numero_cuotas)
            }else{
                valor_cuota = parseInt(this.saldo_pendiente / this.numero_cuotas)
            }

            var nueva_fecha =  moment()
            for (let i = 0; i < this.numero_cuotas; i++){

                switch (this.periodicidad){
                case 1:
                    nueva_fecha = nueva_fecha.add(1,'days')
                    this.cuotas.push({
                        fecha:nueva_fecha.format('YYYY-MM-DD'),
                        valor:valor_cuota,
                    })
                    break;
                case 2:
                    nueva_fecha = nueva_fecha.add(7,'days')
                    this.cuotas.push({
                        fecha:nueva_fecha.format('YYYY-MM-DD'),
                        valor:valor_cuota,
                    })
                    break;
                case 3:
                    nueva_fecha = nueva_fecha.add(15,'days')
                    this.cuotas.push({
                        fecha:nueva_fecha.format('YYYY-MM-DD'),
                        valor:valor_cuota,
                    })
                    break;
                case 4:
                    nueva_fecha = nueva_fecha.add(30,'days')
                    this.cuotas.push({
                        fecha:nueva_fecha.format('YYYY-MM-DD'),
                        valor:valor_cuota,
                    })
                    break;
                default:

                }

            }
        }
    }
}
</script>
<style lang="css" scoped>
.br-12{ border-radius: 12px;}
.border-separador{ border-color: #0892DD; border-width: 2px; }
</style>
